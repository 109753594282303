import { ConvexPolyhedronProps } from "@react-three/cannon";
import { BufferGeometry, Vector3 } from "three";

export function toConvexProps(
    bufferGeometry: BufferGeometry
): ConvexPolyhedronProps["args"] {
  // Get vertices from buffer geometry
  const positions = bufferGeometry.getAttribute("position");
  const vertices: [number, number, number][] = [];
  const tempVector = new Vector3();

  // Extract unique vertices
  for (let i = 0; i < positions.count; i++) {
    tempVector.fromBufferAttribute(positions, i);
    vertices.push([tempVector.x, tempVector.y, tempVector.z]);
  }

  // Get faces (indices)
  const index = bufferGeometry.getIndex();
  const faces: [number, number, number][] = [];

  if (index) {
    // If indexed geometry
    for (let i = 0; i < index.count; i += 3) {
      faces.push([
        index.getX(i),
        index.getX(i + 1),
        index.getX(i + 2)
      ]);
    }
  } else {
    // If non-indexed geometry
    for (let i = 0; i < positions.count; i += 3) {
      faces.push([i, i + 1, i + 2]);
    }
  }

  // Deduplicate vertices and update face indices
  const uniqueVertices: [number, number, number][] = [];
  const vertexMap = new Map<string, number>();

  vertices.forEach((vertex) => {
    const key = vertex.join(",");
    if (!vertexMap.has(key)) {
      vertexMap.set(key, uniqueVertices.length);
      uniqueVertices.push(vertex);
    }
  });

  const updatedFaces = faces.map((face) =>
      face.map(index => {
        const vertex = vertices[index];
        return vertexMap.get(vertex.join(",")) || 0;
      }) as [number, number, number]
  );

  return [
    uniqueVertices,
    updatedFaces,
    [], // Normals are optional in ConvexPolyhedronProps
  ];
}